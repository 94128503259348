
const BASE_URL = "api";

export const signUpApi = BASE_URL + "/signup-user"; // finish
export const loginApi = BASE_URL + "/login-user"; // finish
export const logoutApi = BASE_URL + "/logout";// finish
export const updateUserDataApi = BASE_URL + "/update-userData";
export const checkUserApi = BASE_URL + "/check-user"; // finish
export const checkloginUserApi = BASE_URL + "/check-loginuser"
export const checkOTPApi = BASE_URL + "/check-otp";
export const resendOTPApi = BASE_URL + "/resend-otp";
// export const createHostingPhotosApi = BASE_URL + "/create-hosting-photos";
export const createHostingApi = BASE_URL + "/create-hosting"; //finish
export const getReverseGeolocationApi = BASE_URL + "/get-reverse-geolocation";
export const reserveRoomApi = BASE_URL + "/reserve-room"; // finish
export const confirmRoomApi = BASE_URL + "/confirm-room"; // finish
export const confirmExperienceApi = BASE_URL + "/confirm-experience"; // reject
export const getPendingBookingsApi = BASE_URL + "/get-pending-bookings";// finish twice
export const getArrivingSoonApi = BASE_URL + "/get-arriving-soon"; // finish twice
export const getCheckoutTodayApi = BASE_URL + "/get-checkout-today";// finish twice
export const getUpcomingBookingsApi = BASE_URL + "/get-upcoming-bookings";// finish twice
export const hostRoomApprovalApi = BASE_URL + "/host-room-approval";// finish
export const getBookingDetailsApi = BASE_URL + "/get-booking-details";
export const getUserBookingsApi = BASE_URL + "/get-user-bookings"; // finish
export const getUserHostingsApi = BASE_URL + "/get-user-hostings";// finish
export const getUserDetailsApi = BASE_URL + "/get-user-details";
export const getUserPendingApi = BASE_URL + "/get-user-pending";
export const getUserTripsApi = BASE_URL + "/get-user-trips"; // finish
export const sendMessageApi = BASE_URL + "/send-message";
export const getPriceApi = BASE_URL + "/get-price";
export const CancellationPolicyAPi = BASE_URL + "/get-cancellationPolicy"
export const setRoomPriceApi = BASE_URL + "/set-room-price";// finish
export const splitreserveRoomApi = BASE_URL + "/split-payment-room";
export const getUserSplitReserveApi = BASE_URL + "/get-split-payment"; // finish
export const getSplitBookingDetailsApi = BASE_URL + "/get-splitbooking-details";
export const checkBookingStatusApi = BASE_URL + "/check-booking-status";
export const getOverAllRatingApi = BASE_URL + "/overall-review";
export const getRoomRatingApi = BASE_URL + "/get-room-rating";
export const getHostDetailsApi = BASE_URL + "/get-host-details"; // finish
export const getsubscriptiondetailsApi =  BASE_URL + "/getsubscriptiondetails"

export const addReviewApi = BASE_URL + "/add-review";
//paypal
export const paymentApi = BASE_URL + "/pay";
export const updatenameApi = BASE_URL + "/update-name";
export const updategenderApi = BASE_URL + "/update-gender";
export const updatedobApi = BASE_URL + "/update-dob"; 
export const updateaddressApi = BASE_URL + "/update-address";
export const updateemergencycontactApi = BASE_URL + "/update-emergency-contact";
export const pullemergencycontactApi = BASE_URL + "/pull-emergency-contact";
export const updategovtidApi = BASE_URL + "/update-govt-id";
export const getuserdataApi = BASE_URL + "/get-user-data"

export const getExperienceCategoriesApi = BASE_URL + "/get-experience-categories"
export const getExperienceProvisionsApi = BASE_URL + "/get-experience-provisions"
export const createExperienceApi = BASE_URL + "/create-experience"
export const getExperienceDatasApi = BASE_URL + "/get-experience-datas"

export const updateUserProfileApi = BASE_URL + "/update-user-profile"
export const getUserExperienceHostingsApi = BASE_URL + "/get-user-experience-hostings"
export const deleteExperienceApi = BASE_URL + "/delete-experience"
export const getUserRoomListingsApi = BASE_URL + "/get-user-room-listings"// finish
export const listSettingsApi = BASE_URL + "/list-setting"
export const cancelRoomApi = BASE_URL + "/cancel-room" // finish
export const getCancellationAmountApi = BASE_URL + "/get-cancellation-amount"
export const getCancellationDetailsApi = BASE_URL + "/get-cancellation-details"

export const getUserCancelledApi = BASE_URL + "/get-user-cancelled" // finish

//payment routes 
export const createRazorPaymentApi = BASE_URL + "/create-razorpay-payment"
export const verifyRazorPaymentApi = BASE_URL + "/verify-razorpay-payment"

// Booking and paymen
export const bookingExperienceApi = BASE_URL + "/booking-experience-details"
export const bookExperienceCreatePayments = BASE_URL + "/experience-create-razorpay-payment"
export const bookExperienceVerifyPayments = BASE_URL + "/experience-verify-razorpay-payment"

export const getBookingExperienceDetails = BASE_URL + "/get-booking-experience-details"
export const getExperienceBookingDetails = BASE_URL + "/get-user-experience-booking-details"
export const getEmail = BASE_URL + "/get-user-email"
export const getCompletedExperienceBooking = BASE_URL + "/get-completed-experience-booking"
export const checkExperienceBookingStatus = BASE_URL + "/check-experience-booking-status"
export const confirmPayExperience  = BASE_URL + "/confirm-payment-experience"

export const addExperienceReviewApi = BASE_URL + "/experience-add-review"
export const getOverAllRatingExperience = BASE_URL + "/get-experience-overall-review"

export const updateUserPhoneNumber = BASE_URL + "/update-user-phonenumber"

export const getuserdatasApi = BASE_URL + "/getuserdatas";

export const getsingleroomprice = BASE_URL + "/get-single-room-price";

export const getunreadnotificationApi = BASE_URL + "/getunreadmessagenotification";
export const markasreadoneApi = BASE_URL + "/markus_readone";
export const markasreadallApi = BASE_URL + "/markas-readall";
export const getallnotificationApi = BASE_URL + "/get-message-notification";

export const getcmsApi = BASE_URL + "/get-cmsdata" // finish
export const editroompriceApi = BASE_URL + "/editroomprice";
export const editexperiencepriceApi = BASE_URL + "/editexperienceprice"

export const getstaydateApi = BASE_URL + "/get-staydate";

export const gethostactivesubscriptionApi = BASE_URL + "/get-hostusersubscription";// finish
export const getguestactivesubscriptionApi = BASE_URL + "/get-guestusersubscription";// finish
export const subscribehostApi = BASE_URL + "/subscribehost";// finish
export const subscribeguestApi = BASE_URL + "/subscribeguest";// finish
export const confirmguestsubscription = BASE_URL + "/confirm-guestsubscription";
export const confirmhostsubscription = BASE_URL + "/confirm-hostsubscription";

export const updatebankdetailsApi = BASE_URL + "/update-bankdetails";

export const getfaqApi = BASE_URL + "/get-faqdata";

export const getwithdrawhistoryApi = BASE_URL + "/get-withdraw-details"; // finish

export const cancelbookingApi = BASE_URL + "/getcancelledbooking";// finish twice
export const cancellationtypeApi = BASE_URL + "/get-cancellationtype";

export const checksignupotpApi = BASE_URL + "/signup-otp"; // finish

export const forgotpasswordApi = BASE_URL + "/forgotpassword"; // finish
export const checkotpforgotpasswordApi = BASE_URL + "/checkotp-forgotpassword"; // finish
export const updatepasswordApi = BASE_URL + "/update-password";// finish
export const hostinstantbookingstatusApi = BASE_URL + "/host-instantbookingstatus";
export const switchhostandguestApi = BASE_URL + "/switch-guestandhost"; // finish

export const getguestsubscriptionApi = BASE_URL + "/getusersubscription";
export const sendotpApi = BASE_URL + "/Sendotp";
export const verifyotpApi = BASE_URL + "/verifyotp";

export const unblockdateApi = BASE_URL + "/unblockdates";

export const editspecilpriceApi = BASE_URL + "/editspecialprice";
export const addconcactusApi = BASE_URL + "/addcontactus";
export const getjoinusApi = BASE_URL + "/getjoinus";// finish

export const getusersubscripdataApi = BASE_URL + "/getusersubscriptiondetails"
export const updatehostingdataApi = BASE_URL + "/update-hosting-details"

export const getsinglehostingdataApi = BASE_URL + "/getsinglehostingdetails"


export const getlandcmsApi = BASE_URL + "/get-landcmsdata";// finish

export const updateuserdetailsApi = BASE_URL + "/update-userdetails"; // finish

