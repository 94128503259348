// export const API_URL = "http://localhost:2053/";
// export const FRONT_URL = "http://localhost:3000";
// export const API_URL = "https://backend-jet-to-let.maticz.in/";

// Local
// export const FRONT_URL = "http://localhost:3000";
// export const API_URL = "http://localhost:9096/";

//Testnet
// export const FRONT_URL = "https://flutterpad-demo.pages.dev";
// export const API_URL = "https://backend-jettolet.maticz.in/";  

// export const FRONT_URL = "https://flutterpad-demo.pages.dev";
// export const API_URL = "https://backend-jettolet.maticz.in/"; 
export const FRONT_URL = "https://uat.rentformers.com/";
export const API_URL = "https://backend-test.flutterpads.com/";

// //Mainnet
// export const FRONT_URL = "https://rentformers.com/"
// export const API_URL = "https://api.flutterpads.com/";


// NewprojectTestnet
// export const FRONT_URL = "https://jettolet.maticz.in/"
// export const API_URL = "https://backend-jet-to-let.maticz.in/";


// export const API_URL = "http://43.204.99.101:9096/";
// export const FRONT_URL = "http://flutterpads.maticz.in/";
export const GOOGLE_MAP_API_KEY = "AIzaSyDwQOCtNC-XQRRYsZIg5lXexgr-CrOEXrw";

export const toastOptions = {
  style: {
    border: "1px solid #082e54",
    padding: "16px",
    color: "#082e54",
  },
  iconTheme: {
    primary: "#fbc108",
    secondary: "#082e53",
  },
};
