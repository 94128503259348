import React, { Component, useEffect, useState } from "react";
import {
  Dropdown,
  Tabs,
  Tab,
  Row,
  Col,
  Nav,
  Modal,
  Accordion,
  InputGroup,
  Form,
  Button,
} from "react-bootstrap";
// import { Map, GoogleApiWrapper } from 'google-maps-react';
import logo from "../../Assets/images/favicon.png";
import user from "../../Assets/images/user.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { toastOptions } from "../../config/env";
// places
import Start from "./Propertytype/Start";
import host from "../../Assets/images/hostBg.png";
import PlaceType from "./Propertytype/PlaceType";
import HostQuestion from "../BecomeHost/HostQuestion";
import DescribePlace from "../BecomeHost/Propertytype/DescribePlace";
import PlaceLocation from "../BecomeHost/Propertytype/PlaceLocation";
import Amenities from "./Propertytype/Amenities";
import Space from "./Propertytype/Space";
import PlacePhotos from "./Propertytype/PlacePhotos";
import CreateTitle from "./Propertytype/CreateTitle";
import Highlights from "./Propertytype/Highlights";
import CreateDescription from "./Propertytype/CreateDescription";
import SetPrice from "./Propertytype/SetPrice";
import LastQuest from "./Propertytype/LastQuest";
import CheckList from "./Propertytype/CheckList";
import GuestCount from "./Propertytype/GuestCount";

import HostContent from "../../config/HostContent.json";

import { getHostingDetails } from "../../Hooks/useUserHostingHook";
import {
  uploadHostImages,
  createHostUser,
} from "../../Hooks/useUserHostingHook";
import CreateDescriptionContent from "./Propertytype/DescriptionContent";
import { getUserDatas, getUserEmail } from "../../Hooks/useUserHook";
import RoomCategory from "./Propertytype/RoomCaegory";
import CancellationPolicy from "./Propertytype/CancellationPolicy";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import CustomFilterHeader from "./AfterHost/CustomFilterHeader";
import HostRoomHeaderHeader from "../Common/HostRoomHeader";

const PropertyType = () => {
  const [count, setCount] = useState(0);
  console.log("count val", count);
  const [hostDatas, setHostDatas] = useState([]);
  const [userHostDatas, setUserHostDatas] = useState({ video : "no"});
  console.log("userHostdatas", userHostDatas.photos);
  const [email, setEmail] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);
  console.log("btnStatus", btnStatus);
  const [userDatas, setUserDatas] = useState({});
  console.log("userDatas", userDatas.photos);
  const [Image, setImageData] = useState([]);
  const [video, setVideo] = useState({});
  const [disback, setDisBack] = useState(false);

  const [imagearr, setImageArr] = useState([]);

  console.log("consolegal", imagearr, Image);

  const getHostDatasFunction = async () => {
    const emailid = window.location.pathname.split("/")[2];
    if (
      emailid == "undefined" ||
      emailid == undefined ||
      emailid != getUserEmail()
    ) {
      window.location.href = window.location.origin + "/";
    }
    console.log("email id :", emailid);
    setEmail(emailid);
    setUserDatas(getUserDatas());
    getHostingDetails().then((data) => {
      console.log("data from gethosting data function", data);
      setHostDatas(data);
    });
  };

  const getTitle = (data) => {
    console.log("gettitle data", data)
    if (data.length == 0) {
      setBtnStatus(false);
    } else {
      console.log("data from get title:", data);
      userHostDatas.title = data;
      localStorage.setItem("title", data);
      console.log("getTitle hihi");
      if(data!= "" &&  localStorage.getItem("title") && localStorage.getItem("rules")) {
        setBtnStatus(true);
      }

    }
  };

  const getPlaceType = (data) => {
    console.log("data from get place type:", data);
    userHostDatas.propertyTypeGroup = data;
    localStorage.setItem("propertyTypeGroup", JSON.stringify(data));
    console.log("getPlaceType hihi");
    setBtnStatus(true);
  };

  const getDescribePlace = (data) => {
    console.log("data from get describe place:", data);
    userHostDatas.propertyType = data;
    localStorage.setItem("propertyType", JSON.stringify(data));
    console.log("getDescribePlace hihi");
    setBtnStatus(true);
  };

  const getPlaceLocation = (data) => {
    console.log("data from get place location:", data);
    userHostDatas.address = data;
    localStorage.setItem("address", JSON.stringify(data));
    setBtnStatus(true);
    console.log("getPlaceLocation hihi", btnStatus);
  };

  const getAmenities = (data) => {
    console.log("data from get amenities:", data);
    userHostDatas.amenities = data;
    localStorage.setItem("amenities", JSON.stringify(data));
    if (data?.length == 0) {
      setBtnStatus(false);
    } else {
      console.log("getAmenities hihi");
      setBtnStatus(true);
    }
  };

  const getSpace = (data) => {
    console.log("data from get content:", data);
    userHostDatas.privacyType = data;
    localStorage.setItem("privacyType", data);
    console.log("getSpace hihi");
    setBtnStatus(true);
  };
  const toBase64 = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    console.log("reader.result : ", reader.result);
    // Promise.all(result).then(response=)
    reader.onload = () => reader.result;
    return reader.result;
  };

  const getPhotos = (data) => {
    try {
      // old code
      // var arr = [];
      // var obj = {};
      // console.log("datssgalss", data,JSON.stringify(data));
      // setImageData(data);
      // setBtnStatus(true);
      // userHostDatas.photos = data;
      // Array.from(data).forEach((val) => {
      //   obj.lastModified = val.lastModified;
      //   obj.lastModifiedDate = val.lastModifiedDate;
      //   obj.name = val.name;
      //   obj.size = val.size;
      //   obj.type = val.type;
      //   obj.webkitRelativePath = val.webkitRelativePath;
      //   arr.push(obj);
      // });
      // console.log("photos_datasgak", arr)
      // localStorage.setItem("photos", JSON.stringify(arr));
      // console.log("getPhotos hihi");
      // setBtnStatus(true);


      // my code

      var arr = [];
      var obj = {};
      console.log("datssgalss", data);
      setImageData(data);
      // setBtnStatus(true);
      if(Object.values(data).length > 0) {
        userHostDatas.photos = data;
        for(let i=0; i<Object.values(data).length; i++) {
          obj.lastModified = Object.values(data)[i].lastModified;
          obj.lastModifiedDate = Object.values(data)[i].lastModifiedDate;
          obj.name = Object.values(data)[i].name;
          obj.size = Object.values(data)[i].size;
          obj.type = Object.values(data)[i].type;
          obj.webkitRelativePath = Object.values(data)[i].webkitRelativePath;
          arr.push(obj);
        }
        console.log("photos_datasgak", arr)
        setImageData(data)
        localStorage.setItem("photos", JSON.stringify(arr))
        setImageArr(arr)
        console.log("getPhotos hihi");

      }
      // setBtnStatus(true)
    } catch (e) {
      console.log("errrrr", e);
    }
  };

  const getVideo = (data) => {
    try {
      var arr = [];
      var obj = {};
      console.log("datssgalss", JSON.stringify(data));
      setVideo(data);
      // setBtnStatus(true);
      userHostDatas.video = data;
      // Array.from(data).forEach((val) => {
      //   obj.lastModified = val.lastModified;
      //   obj.lastModifiedDate = val.lastModifiedDate;
      //   obj.name = val.name;
      //   obj.size = val.size;
      //   obj.type = val.type;
      //   obj.webkitRelativePath = val.webkitRelativePath;
      //   arr.push(obj);
      // });
      // localStorage.setItem("photos", JSON.stringify(arr));
      // console.log("getPhotos hihi");
      // setBtnStatus(true);
    } catch (e) {
      console.log("errrrr", e);
    }
  };


  console.log('sdasdasdfsdfsdf', btnStatus)

  const getHighlights = (data) => {
    console.log("data from get highlights:", data);
    userHostDatas.description = data;
    localStorage.setItem("highlights", JSON.stringify(data));
    if (data.length == 0) {
      setBtnStatus(false);
    } else {
      console.log("getHighlights hihi");
      setBtnStatus(true);
    }
  };

  const getPrice = (data) => {
    console.log("data from get price:", data, data?.price , data?.checkin , data?.checkout);

      if (parseFloat(data?.price)>0) {
        // localStorage.setItem("price",data)
        userHostDatas.price = data?.price;
        // setBtnStatus(true);
        console.log("check that side", data);
        // localStorage.setItem("price", JSON.stringify(data));
        console.log("getPrice hihi");
      } else {
        setBtnStatus(false);
        return;
      }

      // userHostDatas.checkintime = new Date()?.setHours(12, 0, 0);
      // userHostDatas.checkouttime = new Date()?.setHours(11, 0, 0);
      if (data?.checkin) {
        userHostDatas.checkintime = data?.checkin;
      } else {
        setBtnStatus(false);
        return;
      }

      if (data?.checkout) {
        userHostDatas.checkouttime = data?.checkout;
      } else {
        setBtnStatus(false);
        return;
      }
    if(data?.price && data?.checkin && data?.checkout){
      setBtnStatus(true)
    }
      // setBtnStatus(true);
    
  };

  const getLastQuest = (data) => {
    console.log("data from get last quest:", data);
    userHostDatas.legal = data;
    // localStorage.setItem("legal",JSON.stringify(data));
    localStorage.setItem("legal", data);
    console.log("getLastQuest hihi");
    setBtnStatus(true);
  };

  const getCheckList = (data) => {
    console.log("data from get check list:", data);
    userHostDatas.instantBooking = data;
    localStorage.setItem("instantBooking", data);
    console.log("getCheckList hihi");
    setBtnStatus(true);
  };

  const getGuestCount = (data) => {
    console.log("data from get guest count:", data);
    if (
      data.reduce(
        (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue?.count),
        0
      ) > 0
    ) {
      userHostDatas.floorPlan = data;
      localStorage.setItem("floorPlan", JSON.stringify(data));
      console.log("getGuestCount hihi");
      setBtnStatus(true);
    } else {
      setBtnStatus(false);
    }
  };

  const getDescriptionContent = (data) => {
    if (data.length == 0) {
      setBtnStatus(false);
    } else {
      if (userHostDatas.rules?.length == 0) {
        setBtnStatus(false);
      } else {
        setBtnStatus(true);
      }
      console.log("getDescriptionContent", data);
      userHostDatas.descriptionContent = data;
      localStorage.setItem("descriptionContent", data);
      console.log("getDescriptionContent hihi");
      // setBtnStatus(true);
    }
  };

  const getrules = (data) => {
    if (data.length == 0) {
      setBtnStatus(false);
    } else {
      if (userHostDatas.descriptionContent?.length == 0) {
        setBtnStatus(false);
      } else {
        setBtnStatus(true);
      }
      console.log("getDescriptionContent", data);
      userHostDatas.rules = data;
      localStorage.setItem("rules", data);
      console.log("getDescriptionContent hihi");
    }
  };


  const getDescription = (data) => {
    try {
      userHostDatas.descriptionContent = data;
      if(data!= "" &&  localStorage.getItem("descriptionContent") && localStorage.getItem("rules")) {
        setBtnStatus(true);
      }
      localStorage.setItem("descriptionContent", data);
    } catch (e) {
      console.log("getDescription_err", e);
    }
  }


  const getAddrules = (data) => {
    try {
      userHostDatas.rules = data;
      if(data != "" && localStorage.getItem("descriptionContent") && localStorage.getItem("title")){
        setBtnStatus(true)
      }
      localStorage.setItem("rules", data);
    } catch (e) {
      console.log('getAddrules_errr', e);
    }
  }

  const createHost = async () => {
    setDisBack(true);
    setBtnStatus(false);
    console.log("hello");
    userHostDatas.email = email;
    userHostDatas.firstName = userDatas.firstName;
    userHostDatas.lastName = userDatas.lastName;
    // userHostDatas.cancellationPolicy = "flexible";
    // userHostDatas.instantBooking = true //userDatas?.instantBooking ? userDatas?.instantBooking : false;
    console.log("userHostDatas in createhost:", userHostDatas);
    console.log("userHostDatas remove", userHostDatas.propertyType,
    userHostDatas.privacyType, userHostDatas.description, userHostDatas.cancellationPolicy,
    );
console.log("enter the all dats",
userHostDatas.title,
userHostDatas.propertyTypeGroup, userHostDatas.address,userHostDatas.amenities,
userHostDatas.photos,  userHostDatas.price, userHostDatas.floorPlan,
userHostDatas.descriptionContent,userHostDatas.category


)
    if (
      userHostDatas.title &&
      userHostDatas.propertyTypeGroup &&
      // userHostDatas.propertyType &&
      userHostDatas.address &&
      userHostDatas.amenities &&
      // userHostDatas.privacyType &&
      userHostDatas.photos &&
      // userHostDatas.description &&
      userHostDatas.price &&
      userHostDatas.legal &&
      userHostDatas.floorPlan &&
      userHostDatas.descriptionContent &&
      // userHostDatas.cancellationPolicy &&
      userHostDatas.category
    ) {
      console.log("createhost", hostDatas[4].path);
      const path = { path: hostDatas[4].path };
      userHostDatas.amenities.push(path);
      console.log("userHostDatas in createhost:", userHostDatas, userHostDatas.photos);
      await createHostUser(userHostDatas);
      toast.success(`Successfully Hosted ${userHostDatas.title} & Soon admin will look into your listing and approve it`, toastOptions);
      localStorage.removeItem("title");
      localStorage.removeItem("propertyTypeGroup");
      localStorage.removeItem("propertyType");
      localStorage.removeItem("address");
      localStorage.removeItem("amenities");
      localStorage.removeItem("privacyType");
      localStorage.removeItem("photos");
      localStorage.removeItem("description");
      localStorage.removeItem("price");
      localStorage.removeItem("legal");
      localStorage.removeItem("instantBooking");
      localStorage.removeItem("floorPlan");
      localStorage.removeItem("descriptionContent");
      localStorage.removeItem("category");
      localStorage.removeItem("cancellationPolicy");
      localStorage.removeItem("rules");
      localStorage.removeItem("arr");
      localStorage.removeItem("test");
      localStorage.removeItem("imageUrl");
      localStorage.removeItem("arr1");
      localStorage.removeItem("amenitiesIndex");
      localStorage.removeItem("placeTypeIndex");
      localStorage.removeItem("roomCategoryIndex");
      window.location.href =
        window.location.origin + `/host-dashboard/listings/${email}/stays`;
    } else {
      toast.error("Enter all datas to host!", toastOptions);
    }
  };

  const btnFunction = () => {
    setCount(count + 1);
    setBtnStatus(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  console.log("count vallllll", count);
  const btnFunction2 = () => {
    console.log("func2 hihi");
    setCount(count - 1);
    setBtnStatus(true);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const getPlaceCategory = (data) => {
    console.log("data get from category :", data);
    userHostDatas.category = data;
    localStorage.setItem("category", JSON.stringify(data));
    if (data?.length == 0) {
      setBtnStatus(false);
    } else {
      console.log("setplacecategory hihi");
      setBtnStatus(true);
    }
  };
  const getCancellationPolicy = (data) => {
    console.log("cancellationPolicy", data);
    localStorage.setItem("cancellationPolicy", JSON.stringify(data));
    userHostDatas.cancellationPolicy = data;
    console.log("getCancellationPolicy hihi");
    setBtnStatus(true);
  };
  useEffect(() => {
    getHostDatasFunction();
  }, []);
  const statuschng = () => {
    console.log("category lemngth", localStorage.getItem("category"));
    JSON.parse(localStorage.getItem("category"))?.length > 0
      ? setBtnStatus(true)
      : setBtnStatus(false);
  };

  const statuschange = () => {
    console.log("Image.length",Image,
  )
    if (imagearr.length == 5) {
      setBtnStatus(false);
    } else {
      setBtnStatus(false);
    }
  };
  useEffect(() => {
    console.log('imagearr.lengthimagearr.length' , imagearr.length, imagearr.length == 5)
    if (imagearr.length == 5) {
      setBtnStatus(true);
    } else {
      setBtnStatus(false);
    }
  }, [imagearr])

  const statuschngammenities = () => {
    JSON.parse(localStorage.getItem("amenities"))?.length > 0
      ? setBtnStatus(true)
      : setBtnStatus(false);
  };

  const switchFunction = () => {
    // console.log(count,'count val')
    let content;
    // switch (count) {
    //   case 0: //Describe your place
    //     content = (
    //       <PlaceType data={hostDatas[0]} onGetPlaceType={getPlaceType} />
    //     );
    //     break;
    //   case 1: //property-type
    //     content = (
    //       <DescribePlace
    //         data={hostDatas[1]}
    //         onGetDescribePlace={getDescribePlace}
    //       />
    //     );
    //     break;
    //   case 2: //privacy-type
    //     content = <Space data={hostDatas[2]} onGetSpace={getSpace} />;
    //     break;
    //   case 3: //What does your place offer?
    //     content = (
    //       <RoomCategory
    //         data={hostDatas[7]}
    //         onGetPlaceCategory={getPlaceCategory}
    //         onstatus={statuschng}
    //       />
    //     );
    //     break;
    //   case 4: // Where is your place located?
    //     content = <PlaceLocation onGetPlaceLocation={getPlaceLocation} />;
    //     break;
    //   case 5: //Share some basics about your place
    //     content = (
    //       <GuestCount data={hostDatas[8]} onGuestCount={getGuestCount} />
    //     );
    //     break;
    //   case 6: //Do you have a cancellation policy?
    //     content = <LastQuest data={hostDatas[5]} onLastQuest={getLastQuest} />;
    //     break;

    //   case 7: //Any standout amenities?
    //     content = (
    //       <Amenities
    //         data={hostDatas[3]}
    //         onGetAmenities={getAmenities}
    //         onstatus={statuschngammenities}
    //       />
    //     );
    //     break;
    //   case 8: //Upload photos
    //     content = (
    //       <PlacePhotos
    //         onGetPhotos={getPhotos}
    //         Images={Image}
    //         onstatus={statuschange}
    //         onGetVideo={getVideo}
    //       />
    //     );
    //     break;
    //   case 9: //title
    //     content = <CreateTitle onGetTitle={getTitle} />;
    //     break;
    //   case 10: //description
    //     content = (
    //       <Highlights data={hostDatas[4]} onGetHighlights={getHighlights} />
    //     );
    //     break;
    //   case 11: //Your listing details
    //     content = (
    //       <CreateDescriptionContent
    //         onGetDescriptionContent={getDescriptionContent}
    //         onGetRules={getrules}
    //       />
    //     );
    //     break;
    //   case 12: //Set your price
    //     content = <SetPrice onGetPrice={getPrice} />;
    //     break;

    //   case 13: //legal
    //     content = (
    //       <CancellationPolicy
    //         data={hostDatas[6]}
    //         onGetCancellationPolicy={getCancellationPolicy}
    //       />
    //     );
    //     break;
    //   case 14: //Preview your listing
    //     content = (
    //       <CheckList data={userHostDatas} onGetCheckList={getCheckList} />
    //     );
    //     break;
    // }
    
    switch (count) {
      case 0: //Describe your place
        content = (
          <PlaceType data={hostDatas[8]} onGetPlaceType={getPlaceType} />
        );
        break;
      case 1: //What does your place offer?
        content = (
          <RoomCategory
            data={hostDatas[1]}
            onGetPlaceCategory={getPlaceCategory}
            onstatus={statuschng}
          />
        );
        break;
      case 2: // Where is your place located?
        content = <PlaceLocation onGetPlaceLocation={getPlaceLocation} />;
        break;
      case 3: //Share some basics about your place
        content = (
          <GuestCount data={hostDatas[0]} onGuestCount={getGuestCount} />
        );
        break;
      case 4: //Do you have a cancellation policy?
        content = <LastQuest data={hostDatas[3]} onLastQuest={getLastQuest} />;
        break;
      case 5: //Any standout amenities?
        content = (
          <Amenities
            data={hostDatas[5]}
            onGetAmenities={getAmenities}
            onstatus={statuschngammenities}
          />
        );
        break;
      case 6: //Upload photos
        content = (
          <PlacePhotos
          imagess= {userHostDatas.photos}
            onGetPhotos={getPhotos}
            Images={Image}
            onstatus={statuschange}
            onGetVideo={getVideo}
            
          />
        );
        break;
      case 7: //Your listing details
        content = (
          <CreateDescriptionContent
            onGetDescriptionContent={getDescription}
            onGetAddRules={getAddrules}
            onGetTitle={getTitle}
          />
        );
        break;
      case 8: //Set your price
        content = <SetPrice onGetPrice={getPrice} price = {userHostDatas?.price} checkin = {userHostDatas?.checkintime} checkout = {userHostDatas?.checkouttime}/>;
        break;
        //   case 14: //Preview your listing
        // content = (
        //   <CheckList data={userHostDatas} onGetCheckList={getCheckList} />
        // );
        // break;
      case 9: //Preview your listing
        content = (
          <CheckList data={userHostDatas} onGetCheckList={getCheckList}
          imagess= {userHostDatas.photos}
          Image={Image} create={() => {createHost()}} back={() => {btnFunction2()}}/>
        );
        break;
    }

    return content;
  };
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();
  const navigatehosthome = () => {
    navigate("/hosting");
  };
  const nextbtn = () => {
    setCounter(counter + 1);
    if (counter == 15) {
      navigatehosthome();
    }
  };
  return (
    <>
      <div id="proptype" className="property-type">
        {/* <Header /> */}
        <HostRoomHeaderHeader />
        <div className="propstype min-height-100vh pb-5 mt-5">
          <div className="container">
            <div className="row">{hostDatas && switchFunction()}</div>
            {console.log("count data", count, btnStatus)}
            <div
              className="hstrm-prv-nxt-btn mt-4"
              id="progressdiv"
              data-counter={count}
            >
             {count != 9 &&  <Button
                className="bck-btn cmn-bg-black-bdr"
                // disabled={disback}
                onClick={() => {
                  count != 0 ? btnFunction2() : setCount(0);
                }}
              >
                Back
              </Button>}
              {/* <Button
                    className="nxt-btn cmn-bg-orange"
                    disabled={!btnStatus}
                    onClick={() => {
                      count != 14 ? btnFunction() : createHost();
                    }}
                  >
                    {count != 14 ? "Next" : "Create"}
                  </Button> */}
             {count != 9 && <Button
                className="nxt-btn cmn-bg-orange"
                disabled={!btnStatus}
                onClick={() => {
                  count != 9 ? btnFunction() : createHost();
                }}
              >
                
                {count != 9 ? "Next" : "Create"}
              </Button>}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PropertyType;
