import React, { Component, useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";

import { Button, Dropdown, Form } from "react-bootstrap";
// import Dropzone from 'react-dropzone-uploader'
// import { getDroppedOrSelectedFiles } from 'html5-file-selector'

import img1 from "../../../Assets/images/where/img1.png";
import img2 from "../../../Assets/images/where/img2.png";
import img3 from "../../../Assets/images/where/img3.png";
import img4 from "../../../Assets/images/where/img4.png";
import img5 from "../../../Assets/images/where/img5.png";

import UploadImg from "../../../Assets/images/hostroom/upload-img.png";

import { uploadHostImages } from "../../../Hooks/useUserHostingHook";
import { toast, Toaster } from "react-hot-toast";
import { toastOptions } from "../../../config/env";

const PlacePhotos = (props) => {
  const [imageFiles, setImageFiles] = useState([]);
  const [previewUrl, setPreviewUrl] = useState([]);
  const [base64, setBase64] = useState([]);
  const [base64data, setBase64data] = useState([]);
  const [imgp1, setImagep1] = useState("");
  const [imgp2, setImagep2] = useState("");
  const [imgp3, setImagep3] = useState("");
  const [imgp4, setImagep4] = useState("");
  const [imgp5, setImagep5] = useState("");

  //video
  const [videoname, setVideoname] = useState("");
  const [video, setVideo] = useState({});

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    let Image = JSON.parse(localStorage.getItem("imageUrl"));
    props.onstatus();
    if (props.Images.length >= 20) {
      let arr = [];
      Array.from(props.Images).forEach(async (val) => {
        setPreviewUrl([...previewUrl, URL.createObjectURL(val)]);
        arr.push(URL.createObjectURL(val));
      });
      setImagep1(URL.createObjectURL(props.Images[0]));
      setImagep2(URL.createObjectURL(props.Images[1]));
      setImagep3(URL.createObjectURL(props.Images[2]));
      setImagep4(URL.createObjectURL(props.Images[3]));
      setImagep5(URL.createObjectURL(props.Images[4]));
      setPreviewUrl(arr);
    }
    let data = JSON.parse(localStorage.getItem("test"));
    setBase64(data);
    let arrdata = localStorage.getItem("arr1");
  }, [props.Image]);

  const HandleFile = (data) => {
    var file = data.target.files;
    var reader = new FileReader();
    if (file.length >= 20) {
      setImageFiles(file);
      let arr = [];
      let base64Arr = [];
      let arr1 = [];
      Array.from(file).forEach(async (val) => {
        console.log("datasarrayfrom", val);
        var fileName = val.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (
          extFile == "jpg" ||
          extFile == "jpeg" ||
          extFile == "png" ||
          extFile == "webp"
        ) {
          arr1.push(val);
          setPreviewUrl([...previewUrl, URL.createObjectURL(val)]);
          arr.push(URL.createObjectURL(val));
          const baseImg = await toBase64(val);
          base64Arr.push(baseImg);
          arr1.push(val);
          console.log("base^$Arr", base64Arr);
          console.log("inside foreach :", arr1);
          localStorage.setItem("test", JSON.stringify(base64Arr));
          // localStorage.setItem("photos",JSON.stringify())
          setBase64data(base64Arr);
        } else {
          toast.error("Invalid file format !", toastOptions);
        }
      });
      localStorage.setItem("imageUrl", JSON.stringify(arr));
      localStorage.setItem("arr1", JSON.stringify(arr1));
      setPreviewUrl(arr);

    } else {
      toast.error("Must select atleast 20 photos !", toastOptions);
    }
  };





  const uploadImages = async () => {
    props.onGetPhotos(imageFiles);
    console.log('imageFiles', imageFiles)
    if (imageFiles.length <= 4) {
      toast.error("Please Upload 20 or More photos !", toastOptions);
    }
  };

  const fileParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };
  const onFileChange = ({ meta, file }, status) => {
    console.log(status, meta, file);
  };
  const onSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  const selectFileInput = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const textMsg = files.length > 0 ? "Upload Again" : "Select Files";
    return (
      <label className="btn btn-danger mt-4">
        {textMsg}
        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles);
            });
          }}
        />
      </label>
    );
  };

  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [file4, setFile4] = useState("");
  const [file5, setFile5] = useState("");
  const ValidateFile = (data) => {
    try{
        var fileName = data.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp" || extFile=="svg"){
            return ""
        }
        else{
            return "Invalid file format"
        }
    }
    catch(e){
        console.log("Error on validate filer" , e);
    }
}


  const handleFile1 = async(e) => {
    let res = ValidateFile(e?.target?.files[0]);
    console.log("filefilefilefilefilefilefilefile" , e?.target?.files[0]);
    if(res){
      toast.error("Invalid File Format");
    }
    else {setFile1(e.target.files[0]);
      setImageFiles({0 : e.target.files[0],1 : file2, 2 : file3, 3 : file4,4 : file5})
      // const baseImg = await toBase64(e?.target?.files[0]);
      // localStorage.setItem("image1" , localStorage.setItem("image1" , JSON.stringify(e?.target?.files[0])));
      
    }
  };
  const handleFile2 = (e) => {
    let res = ValidateFile(e?.target?.files[0]);
    if(res){
      toast.error("Invalid File Format");
    }
    else {
      localStorage.setItem("image2" , URL.createObjectURL(e?.target?.files[0]));
      setFile2(e.target.files[0]);
      setImageFiles({0 : file1,1 : e.target.files[0], 2 : file3, 3 : file4,4 : file5})
    }
    
  };
  const handleFile3 = (e) => {
    if(e?.target?.files[0]){
      let res = ValidateFile(e?.target?.files[0]);
    if(res){
      toast.error("Invalid File Format");
    }
    else {
      setFile3(e.target.files[0]);
      setImageFiles({0 : file1,1 : file2, 2 : e.target.files[0], 3 : file4,4 : file5})
      localStorage.setItem("image3" , JSON.stringify(e?.target?.files[0]));
      
    }
    }
    
    
  };
  const handleFile4 = (e) => {
    let res = ValidateFile(e?.target?.files[0]);
    if(res){
      toast.error("Invalid File Format");
    }
    else {
      setFile4(e.target.files[0]);
      setImageFiles({0 : file1,1 : file2, 2 : file3, 3 : e.target.files[0],4 : file5})
      localStorage.setItem("image4" , JSON.stringify(e?.target?.files[0]));
    }
  };
  const handleFile5 = (e) => {
    let res = ValidateFile(e?.target?.files[0]);
    if(res){
      toast.error("Invalid File Format");
    }
    else {
      setFile5(e.target.files[0]);
      setImageFiles({0 : file1,1 : file2, 2 : file3, 3 : file4,4 : e?.target?.files[0]})
      localStorage.setItem("image5" , JSON.stringify(e?.target?.files[0]));
    }
  };
  console.log("files1_darta", file1)



  // my changes
  useEffect(() => {
    console.log("file1 datas", file1, typeof file1, typeof file1 == "object");
    if (typeof file1 == "object" && typeof file2 == "object" && typeof file3 == "object" && typeof file4 == "object" && typeof file5 == "object") {
      let files = [file1, file2, file3, file4, file5];
      console.log("file1, file2, file3, file4, file5",
      file1, file2, file3, file4, file5, files)
      if (files?.length >= 5) {
        let arr = [];
        let base64Arr = [];
        let arr1 = [];
        setImageFiles({0 : file1,1 : file2, 2 : file3, 3 : file4,4 : file5})
        Array.from(files).forEach(async (val) => {
          console.log("datasarrayfrom", val);
          var fileName = val.name;
          var idxDot = fileName.lastIndexOf(".") + 1;
          var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
          if (
            extFile == "jpg" ||
            extFile == "jpeg" ||
            extFile == "png" ||
            extFile == "webp"
          ) {
            arr1.push(val);
            setPreviewUrl([...previewUrl, URL.createObjectURL(val)]);
            arr.push(URL.createObjectURL(val));
            const baseImg = await toBase64(val);
            base64Arr.push(baseImg);
            arr1.push(val);
            console.log("base^$Arr", base64Arr);
            console.log("inside foreach :", arr1);
            localStorage.setItem("test", JSON.stringify(base64Arr));
            // localStorage.setItem("photos",JSON.stringify())
            setBase64data(base64Arr);
          } else {
            toast.error("Invalid file format !", toastOptions);
          }
          localStorage.setItem("imageUrl", JSON.stringify(arr));
          localStorage.setItem("arr1", JSON.stringify(arr1));
          setPreviewUrl(arr);
        });

        
      }
    }
  }, [file1, file2, file3, file4, file5]);
  function getPhoto(img) {
    var base64 = img;
    var base64Parts = base64.split(",");
    var fileFormat = base64Parts[0].split(";")[1];
    var fileContent = base64Parts[1];
    var file = new File([fileContent], "file name here", {type: fileFormat});
    return file;
 }

  useEffect(() => {
    // if(localStorage.getItem("image1")){
    //   let file = getPhoto(localStorage.getItem("image1"))
    //   console.log("filefilefilefilefilefilefilefile" , file);
    //   setFile1(localStorage.getItem("image1"))
    // }
    // if(localStorage.getItem("image2")){
    //   setFile2(JSON.parse(localStorage.getItem("image2")))
    // }
    // if(localStorage.getItem("image3")){
    //   setFile3(JSON.parse(localStorage.getItem("image3")))
    // }

    // if(localStorage.getItem("image4")){
    //   setFile4(JSON.parse(localStorage.getItem("image4")))
    // }

    // if(localStorage.getItem("image5")){
    //   setFile5(JSON.parse(localStorage.getItem("image5")))
    // }
    console.log("props?.Images" , props?.Images ,props?.Images[0]);
    if(props?.Images[0]){
      setFile1(props?.Images[0]);
    }

    if(props?.Images[1]){
      setFile2(props?.Images[1]);
    }

    if(props?.Images[2]){
      setFile3(props?.Images[2]);
    }

    if(props?.Images[3]){
      setFile4(props?.Images[3]);
    }

    if(props?.Images[4]){
      setFile5(props?.Images[4]);
    }
  } , [props?.Images])
  useEffect(() => {
    props.onGetPhotos(imageFiles);
    console.log('imageFiles', imageFiles)
    // if (imageFiles.length <= 4) {
    //   toast.error("Please Upload 20 or More photos !", toastOptions);
    // }
  }, [imageFiles]);




  return (
    <>
      <div className="col-lg-12 col-xl-12 col-md-12">
        <div className="header-txt">
          <h2 className="fw-800">Upload photos</h2>
          <p className="des-txt">
            Showcase your place by uploading some photos and videos
          </p>
        </div>
        <div className="row mt-5">
          <div className="col-12 col-sm-12 col-lg-6 col-xl-6 mb-2">
            <Button className="custom-uploadImgBtn bigImg">
              <div className="pickedImg">
                {file1 ? (
                  <img src={URL.createObjectURL(file1)} className="img-fluid choosed-img" />
                ) : (
                  <div className="withoutImg">
                    <img src={UploadImg} className="upload-img img-fluid" />
                    <p className="mt-1 fw-600 upload-txt">Upload Image</p>
                  </div>
                )}
              </div>
              <Form className="hiddenInput">
                <Form.Control
                  type="file"
                  multiple
                  onChange={handleFile1}
                  className="uploadInp"
                />
              </Form>
            </Button>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div className="row">
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                <Button className="custom-uploadImgBtn smallImg">
                  <div className="pickedImg">
                    {file2 ? (
                      <img src={URL.createObjectURL(file2)} className="img-fluid choosed-img" />
                    ) : (
                      <div className="withoutImg">
                        <img src={UploadImg} className="upload-img img-fluid" />
                        <p className="mt-1 fw-600 upload-txt">Upload Image</p>
                      </div>
                    )}
                  </div>
                  <Form className="hiddenInput">
                    <Form.Control
                      type="file"
                      multiple
                      onChange={handleFile2}
                      className="uploadInp"
                    />
                  </Form>
                </Button>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                <Button className="custom-uploadImgBtn smallImg">
                  <div className="pickedImg">
                    {file3  ? (
                      <img src={URL.createObjectURL(file3)} className="img-fluid choosed-img" />
                    ) : (
                      <div className="withoutImg">
                        <img src={UploadImg} className="upload-img img-fluid" />
                        <p className="mt-1 fw-600 upload-txt">Upload Image</p>
                      </div>
                    )}
                  </div>
                  <Form className="hiddenInput">
                    <Form.Control
                      type="file"
                      multiple
                      onChange={handleFile3}
                      className="uploadInp"
                    />
                  </Form>
                </Button>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                <Button className="custom-uploadImgBtn smallImg">
                  <div className="pickedImg">
                    {file4? (
                      <img src={URL.createObjectURL(file4)} className="img-fluid choosed-img" />
                    ) : (
                      <div className="withoutImg">
                        <img src={UploadImg} className="upload-img img-fluid" />
                        <p className="mt-1 fw-600 upload-txt">Upload Image</p>
                      </div>
                    )}
                  </div>
                  <Form className="hiddenInput">
                    <Form.Control
                      type="file"
                      multiple
                      onChange={handleFile4}
                      className="uploadInp"
                    />
                  </Form>
                </Button>
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-2">
                <Button className="custom-uploadImgBtn smallImg">
                  <div className="pickedImg">
                    {file5 ? (
                      <img src={URL.createObjectURL(file5)} className="img-fluid choosed-img" />
                    ) : (
                      <div className="withoutImg">
                        <img src={UploadImg} className="upload-img img-fluid" />
                        <p className="mt-1 fw-600 upload-txt">Upload Image</p>
                      </div>
                    )}
                  </div>
                  <Form className="hiddenInput">
                    <Form.Control
                      type="file"
                      multiple
                      onChange={handleFile5}
                      className="uploadInp"
                    />
                  </Form>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-md-12">
        <div className="placetype rightcont mb-5 describeplace ">
          <Scrollbars
            style={{ height: "calc(100vh - 320px)" }}
            className="scroll"
          >
            <div className="row  px-3 align-items-center height-100 mb-90 dragphotos">
              <div className="text-center dashedbord d-none">
                <span className="fa fa-picture-o" />
                <h5>Drag your photos here</h5>
                <p>Add atleast 5 photos</p>

                <Button variant="link" className="text-dark">
                  Upload from your Device
                </Button>
              </div>
              <h4>Click to Upload</h4>

              <div className="upphoto my-3 upphoto_big">
                <Button variant="link" className="nounder text-dark me-2 mb-2">
                  <span className="fa fa-video-camera" />

                  <p>{videoname ? videoname : "Upload video"}</p>

                  <input
                    type="file"
                    onChange={(e) => {
                      var image = e?.target?.files[0];
                      var fileName = image.name;
                      var idxDot = fileName.lastIndexOf(".") + 1;
                      var extFile = fileName
                        .substr(idxDot, fileName.length)
                        .toLowerCase();
                      if (
                        extFile == "mp4" ||
                        extFile == "mov" ||
                        extFile == "m4v" ||
                        extFile == "mkv" ||
                        extFile == "avi"
                      ) {
                        setVideoname(e?.target?.files[0]?.name);
                        setVideo(e?.target?.files[0]);
                        props?.onGetVideo(e?.target?.files[0]);
                      } else {
                        toast.error("Invalid Video Format!..");
                      }
                    }}
                  />
                </Button>
              </div>

              <div className="uploadedpics">
                <div className="d-flex justify-content-between  mb-4">
                  <div>
                    <h4>Add photos</h4>
                    <Button
                      className="btn-theme"
                      onClick={uploadImages}
                      disabled={imageFiles.length < 5}
                    >
                      Upload
                    </Button>
                  </div>
                </div>
                <div className="images">
                  <div className="row">
                    <div className="col-md-12 position-relative">
                      <Button className="fullbutton">
                        <div className="d-flex justify-content-between position-absolute imagedrop px-3 pt-3">
                          <Button className="btn white-btn">Cover Photo</Button>
                        </div>
                        {console.log(
                          "previewursl",
                          previewUrl != "",
                          previewUrl[0],
                          base64
                        )}
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[0]
                              : base64
                              ? base64[0]
                              : img1
                          }
                        />
                        <Form className="hideinput">
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="file"
                              multiple
                              onChange={HandleFile}
                            />
                          </Form.Group>
                        </Form>
                      </Button>
                    </div>
                    <div className="col-md-6 position-relative">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[1]
                              : base64
                              ? base64[1]
                              : img2
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>
                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[2]
                              : base64
                              ? base64[2]
                              : img3
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>
                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[3]
                              : base64
                              ? base64[3]
                              : img4
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>
                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[4]
                              : base64
                              ? base64[4]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[4]
                              : base64
                              ? base64[4]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[5]
                              : base64
                              ? base64[5]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[6]
                              : base64
                              ? base64[6]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[6]
                              : base64
                              ? base64[6]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[7]
                              : base64
                              ? base64[7]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[8]
                              : base64
                              ? base64[8]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[9]
                              : base64
                              ? base64[9]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[9]
                              : base64
                              ? base64[9]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[10]
                              : base64
                              ? base64[10]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[11]
                              : base64
                              ? base64[11]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[12]
                              : base64
                              ? base64[12]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[13]
                              : base64
                              ? base64[13]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[14]
                              : base64
                              ? base64[14]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[15]
                              : base64
                              ? base64[15]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[16]
                              : base64
                              ? base64[16]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[17]
                              : base64
                              ? base64[17]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[18]
                              : base64
                              ? base64[18]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>

                    <div className="col-md-6">
                      <Button className="fullbutton">
                        <img
                          src={
                            previewUrl != ""
                              ? previewUrl[19]
                              : base64
                              ? base64[19]
                              : img5
                          }
                        />

                        <div className="uploadimage d-flex dashedbord align-items-center justify-content-center">
                          <span className="fa fa-picture-o" />
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Toaster position="top-center" reverseOrder={false} />
          </Scrollbars>
        </div>
      </div> */}
    </>
  );
};

export default PlacePhotos;
