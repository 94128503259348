import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import { toastOptions } from "../../../config/env";
import {
  editspecialdatehooks,
  unblockdatephooks,
} from "../../../Hooks/useUserHook";

const ConfirmAvailcont = (props) => {
  console.log("confirmavailcount propsdata", props);
  const [price, setPrice] = useState();
  const [button, setButton] = useState(true);

  const [startDate, setStartDate] = useState("");

  useEffect(() => {
    console.log("props?.selectedref", props?.selectedref, props?.block);
    if (props?.selectedref?.price || props?.butn) {
      setButton(props?.butn);
      setPrice(props?.selectedref?.price);
    } else {
      setPrice("");
    }
  }, [props?.butn, props?.selectedref]);

  const setPricePerDay = (e) => {
    if (e.target.id == "price") {
      props.onSetPriceDate(price);
    } else {
      props.onSetPriceDate("****");
    }
  };

  const unblockdate = async () => {
    let payload = {
      id: props?.selectedroom,
      reference: props?.selectedref?.reference,
    };
    console.log("unblockdate payload", payload);
    let result = await unblockdatephooks(payload);
    toast.success("Date Unblocked Successfully!", toastOptions);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  const editspecialprice = async () => {
    let payload = {
      id: props?.selectedroom,
      reference: props?.selectedref?.reference,
      price: price
    };
    let result = editspecialdatehooks(payload);
    toast.success("Price Edited Successfully!", toastOptions);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  return (
    <div className="availability pt-4">
      {/* <h4 className="dates">Wed, 24 Aug </h4> */}
      {/* <div className="d-flex jc-between my-5">
            <h6 className="mb-0">Available</h6>
            <div>
                <Button variant="outline-dark" className="circlebtn me-2"><span className="fa fa-times"></span></Button>
                <Button variant="dark" className="circlebtn"><span className="fa fa-check"></span></Button>
            </div>
        </div>
        <hr className="themehr"/> */}
      <h4 className="mb-0 fw-600 pricing-txt themeclr1">Pricing</h4>
      <hr className="themehr" />
      <div className="show_detail_dta">
        {/* {props?.selectedref?.startDate && <div>
    <p>From date : {props?.selectedref?.startDate && new Date(props?.selectedref?.startDate)?.toLocaleDateString()}</p>
    <p>To date : {props?.selectedref?.endDate && new Date(props?.selectedref?.endDate)?.toLocaleDateString()}</p>
    <p>Price : {props?.selectedref?.price && props?.selectedref?.price}</p>
    
</div>} */}
        {console.log("props?.selectedref", props?.selectedref)}
        {props?.selectedref?.checkInDate && (
          <div>
            <p>
              From date :{" "}
              {props?.selectedref?.checkInDate &&
                new Date(props?.selectedref?.checkInDate)?.toLocaleDateString()}
            </p>
            <p>
              To date :{" "}
              {props?.selectedref?.checkOutDate &&
                new Date(
                  props?.selectedref?.checkOutDate
                )?.toLocaleDateString()}
            </p>
            {/* <p>Price : {props?.selectedref?.price && props?.selectedref?.price}</p> */}
            <button className="btn btn-theme my-3 me-3" onClick={unblockdate}>
              Unblock
            </button>
          </div>
        )}
        {console.log("props?.blockdasdfasdf", props?.block)}
        {!props?.selectedref?.checkInDate && (
          <p>
            From Date:{" "}
            {props?.from ? new Date(props?.from)?.toLocaleDateString() : ""}
          </p>
        )}
        <div>
          {!props?.selectedref?.checkInDate && (
            <p>
              To Date:{" "}
              {props?.to ? new Date(props?.to)?.toLocaleDateString() : ""}
            </p>
          )}
          {/* <div className="todate mb-3">
            <p className="mb-0">Edit To Date</p>
        <DatePicker
        minDate={new Date(props?.from)}
      showIcon
      excludeDates = {props?.excludeDates}
      selected={props?.to ? new Date(props?.to) : ""}
      onChange={(date) => {props?.change(date) }}
    />
        </div> */}
        </div>
      </div>
      {/* !props?.block */}
      { !props?.block && (
        <button className="w-full text-start changeprice mb-2">
          <p className="f-12">Nightly price:</p>
          <input
            type="Number"
            min={1}
            value={price}
            className="w-full nobord"
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
          {/* <p>$ 658</p> */}
        </button>
      )}
      <div className="mt-2 d-flex align-items-center gap-2 price-ft-btn">
        {!props?.block ? (
          !props?.selectedref?.price ? (
            <button
              className="btn cmn-bg-orange"
              id="price"
              onClick={setPricePerDay}
              // disabled={button}
            >
              Set Price
            </button>
          ) : (
            <button
              className="btn cmn-bg-orange"
              id="price"
              onClick={editspecialprice}
              // disabled={button}
            >
              Edit Price
            </button>
          )
        ) : (
          <></>
        )}
        {!props?.block && (
          <button
            className="btn cmn-bg-orange"
            id="block"
            // disabled={button}
            onClick={setPricePerDay}
          >
            Block Dates
          </button>
        )}
      </div>
      {/* <h6 className="mt-4">Custom settings</h6>
        <p className="text-muted">
        Adjust your length-of-stay settings or add a discount for the selected dates to 
        encourage guests to book.
        </p>
        <button className="btn btn-theme my-4" >+ Add custom settings</button>
        <hr className="themehr"/>

    <h6 className="mt-4 d-flex jc-between mb-0">Private note<b><Button variant="link" className="text-dark p-0">Edit</Button></b>
      </h6>
      <p>jhgdu</p> */}
    </div>
  );
};

export default ConfirmAvailcont;
